import React from 'react';
import axios from 'axios';
import { Button, Input, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {setMainboard, setSideboard, setDraftboard} from "../redux/deckListSlice";
import { setDeckName, setFormat, setColors, setCommander } from "../redux/deckMetaDataSlice";
import { setShowAlert, setAlertMsg, setAlertType} from "../redux/alertSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getCardById, getCardByName } from "../controllers/ScryfallController";
import styles from "./DeckLoader.module.css";

const { Search } = Input;
const { TextArea } = Input;

export default function DeckLoader()  {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  
  const decks = useSelector((state) => state.decks);
  const [currentNavBar, setNavBarSelected] = useState('navBarSelected');

  const getAndLoadDeckEditor = (event) => {
    // Let's send the saved object to the backend
    axios({ "method": "get",
    "url": "/api/getDeck/?urlname=" + event,
    "headers": "Content-Type: application/json"
    }).then((response) => {
        if (response.data.Code === 404) {
          dispatch(setShowAlert(true));
          dispatch(setAlertMsg("Deck with shortname " + event + " not found. Please check case-sensitivity."))
          dispatch(setAlertType("error"));
          return
        }
        let response_data = response["data"]["Decklist"];

        // update state for deck metadata
        let deck_name = response_data["DeckName"];
        let format = response_data["Format"];
        let colors = response_data["Colors"];
        dispatch(setDeckName(deck_name));
        dispatch(setFormat(format));
        dispatch(setColors(colors));

        // call scryfall for commander
        let commander = response_data["Commander"];
        if (JSON.stringify(commander) !== '{}') {
          console.log("looking up commander!");
          let commander_query = getCardById(commander["ScryfallId"]).then((card) => {
            return card // return card data + column + board_name
          });
          Promise.all([commander_query]).then((promise_returns) => {
            dispatch(setCommander(promise_returns[0]));
          })
        }
        
        // begin getting decklist info
        let scryfall_promises = [];
        let backend_decklist = response_data["Sections"];

        // Loop through the response to find the card scryfall ID's
        for (let section_index in backend_decklist) {
            let board_name = backend_decklist[section_index]["BoardName"];
            let board = backend_decklist[section_index]["Cards"]
            for (let card_index in board) {
              // Create a promise that calls out to scryfall for card data
              let card_data = getCardById(board[card_index]["ScryfallId"]).then((card) => {
                return [card, board[card_index]["Column"], board_name] // return card data + column + board_name
              });
              scryfall_promises.push(card_data);
            }
        }

        /* Await all scryfall promises before updating deck store*/
        Promise.all(scryfall_promises).then((promise_returns) => {
          // We're loading a list, clear the decklist in state
          let duplicate_main = [[],[],[],[],[],[],[],[]];
          let duplicate_side = [[],[],[],[],[],[],[],[]];
          let duplicate_draft = [[],[],[],[],[],[],[],[]];
          promise_returns.map((prom) => {
            if (prom[2] === "mainboard") {
            duplicate_main[prom[1]].push(prom[0]);
            }
            else if (prom[2] === "sideboard") {
              duplicate_side[prom[1]].push(prom[0]);
            }
            else {
              duplicate_draft[prom[1]].push(prom[0]);
            }
          });
          dispatch(setMainboard(duplicate_main));
          dispatch(setSideboard(duplicate_side));
          dispatch(setDraftboard(duplicate_draft));

          /* After all data is set, redirect the page to /deck/ with the shortname */
          navigate("../deck/" + event, {relative: "path"});
          return;
        })
    }).catch((error) => {
      console.log(error)
      // add alert that loading failed
      dispatch(setShowAlert(true));
      dispatch(setAlertMsg("Failed to load deck. Please check case-sensitive shortname and try again."))
      dispatch(setAlertType("error"));
    })
  }
  
  function determineColumnFromManaValue(card) {
    // Given a full card json, determine which column to put it on
    if (card.type_line.includes("Land")) {
      return 6;  // last columm
    }
    if (card.cmc == 0) {
      return 0;
    }
    if (card.cmc <= 5) {
      return card.cmc - 1;
    }
    return 5; // column before lands
  }

  const loadClipBoardDeckList = (event) => {
    // First Parse the text area data
    let decklist_pasted = document.getElementById("deck-pasted").value;
    let parsed = decklist_pasted.split('\n');
    let decklist_formatted = {};
    for (let i = 0; i < parsed.length; i += 1) {
      parsed[i] = parsed[i].trim(); // remove front & back spaces
      if (parsed[i] === "") {
        continue  // skip blank lines
      }
      let count = Number.parseInt(parsed[i].substring(0, parsed[i].indexOf(' ')));
      let card_name = parsed[i].substring(parsed[i].indexOf(' ') + 1);
      decklist_formatted[card_name] = count;
    }

    // next call scryfall for all the cards
    let scryfall_promises = [];
    for (let card_name in decklist_formatted) {
      let card_data = getCardByName(card_name).then((card) => {
        return [card_name, card]
      });
      scryfall_promises.push(card_data);
    }

    // finally, update the store
    let duplicate_main = [[],[],[],[],[],[],[],[]];
    Promise.all(scryfall_promises).then((promise_returns) => {
      promise_returns.map((prom) => {
        for (let count=0; count < decklist_formatted[prom[0]]; count++) {
          let column_andy = determineColumnFromManaValue(prom[1])
          duplicate_main[column_andy].push(prom[1]);
        }
      });
      dispatch(setMainboard(duplicate_main));

      // and now lets go to the homepage
      setNavBarSelected("home");
      navigate("../", {relative: "path"});
      setNavBarSelected("home");
    })
  }
    
    return (
    <div className={styles.deckLoader}>
      <br></br>
      <h2>Load Deck</h2>
      <br></br>
      <p>If you know the shortname of your deck, you can enter it here to load it for editing/sharing. </p>
      <p> Alternatively, you can go directly to https://sorrinslist.com/deck/YourShortName</p>
      <br></br>
      <Space.Compact style={{ width: '50%' }}>
        <Search
          placeholder="OrzovhVampirePlaneswalker"
          enterButton="Load Deck"
          size="large"
          onSearch={getAndLoadDeckEditor}
        />
      </Space.Compact>
      <br></br>
      <br></br>
      <div>
        If you have your decklist, you can load it here &nbsp;
        <Button type="primary" onClick={loadClipBoardDeckList}>Load</Button>
        <br></br>  
      </div>
      <Space.Compact style={{ width: '75%' }}>
        <TextArea
          id="deck-pasted"
          rows={7}
          placeholder="Paste your decklist here"
          />
      </Space.Compact>
    </div>
  );
  
}
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef } from "react";
import {setShowAlert, setAlertMsg, setAlertType} from "../redux/alertSlice";
import { Droppable, Draggable} from 'react-beautiful-dnd';
import MagicCard from "./Card"
import Column from './Column';
import CommandZone from "../components/CommandZone";
import DisplayStyle from "./DisplayStyle"
import {
    boxesIntersect,
    useSelectionContainer
} from "@air/react-drag-to-select";
import { setCardBatch } from "../redux/draggingCardsSlice";
import { setDefaultSortOrder, sortMainboardByManaValue, sortMainbordByColor } from '../redux/deckListSlice';
import axios from 'axios';
import styles from "./Mainboard.module.css"
import Panel from "../views/Panel"
import Toolbar from './Toolbar';
import MetaData from './MetaData';

export default function Mainboard(props) {

    const decks = useSelector((state) => state.decks);
    const dragging = useSelector((state) => state.dragging);
    const deckName = useSelector((state) => state.deckMetaData.deckName);
    const format = useSelector((state) => state.deckMetaData.format);
    const colors = useSelector((state) => state.deckMetaData.colors);
    const commander = useSelector((state) => state.deckMetaData.commander);
    
    let defaultSortOrder = decks.defaultSortOrder
    const dispatch = useDispatch();

    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const [selectionBox, setSelectionBox] = useState();
    const [selectedCards, setSelectedCards] = useState([]);
    const arrayOfAllCards = useRef([]);

    const elementsContainerRef = useRef(null);
    
    // Shift click dragging element
    const { DragSelection } = useSelectionContainer({
        eventsElement: document.getElementById("root"),
        isEnabled: isShiftPressed,
        selectionProps: {
            style: {
                zIndex: 100, // So the drag box is in front of the cards
                marginTop: 46, // Size of the menu header
            }
        },
        onSelectionChange: (box) => {
            // To deal with scroll
            const scrollAwareBox = {
                ...box,
                // Deal with offset from the menu
                top: (box.top + window.scrollY),
                left: box.left + window.scrollX 
            }

            setSelectionBox(scrollAwareBox)
            const indexesToSelect = []
            arrayOfAllCards.current.forEach((item, index) => {
                if (boxesIntersect(scrollAwareBox, item.dimensions)) {
                    // push its draggableId (i.e. mainboard-00)
                    indexesToSelect.push(item.element.parentNode.getAttribute('data-rbd-draggable-id'));
                }
            });
            setSelectedCards(indexesToSelect);
        },
        shouldStartSelecting: (target) => {
            // Enable dragging only within the data-disableselect={false} elements
            if (target instanceof HTMLElement) {
              let el = target;
              while (el.parentElement && !el.dataset.disableselect) {
                el = el.parentElement;
              }
              return el.dataset.disableselect !== "true";
            }
            return false;
        },
        onSelectionStart: () =>{
            //console.log("OnSelectionStart")
        },
        onSelectionEnd: () =>{
            dispatch(setCardBatch(selectedCards))
        },
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Shift') {
            setIsShiftPressed(true);
        }
    };
    const handleKeyUp = (event) => {
        if (event.key === 'Shift') {
            setIsShiftPressed(false);
        }
    };

    // Might be able to be refactored to instead just look through the mainboard array
    // Check to see if we can directly grab an element based off its draggableid
    // so that we can get it's bounds (since we can construct its draggableid based on its
    // location in in the nested array) decks.mainboard[i][j] = > mainboard-ij
    function getThirdLevelDescendants(parent) {
        let thirdLevelDescendants = [];
        function getDescendants(element, depth) {
          const children = element.children;
          for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (depth === 3) {
              thirdLevelDescendants.push(child);
            } else {
              getDescendants(child, depth + 1);
            }
          }
        }
        getDescendants(parent, 1);
        return thirdLevelDescendants;
      }

    useEffect(() => {
        // If the card is within the bounds of the created drag box
        if (elementsContainerRef.current) {
            arrayOfAllCards.current=[]
            const thirdLevelDescendants = getThirdLevelDescendants(elementsContainerRef.current);
            for (let i=0; i < thirdLevelDescendants.length; i++) {
                const { left, top, width, height } = thirdLevelDescendants[i].getBoundingClientRect();
                arrayOfAllCards.current.push({
                    dimensions:{
                        left,
                        top: top,
                        width,
                        height: height - 248, // 280px - 248px, get just the top of the card
                    },
                    element: thirdLevelDescendants[i]
                });
            }
        }

    }, [selectionBox, selectedCards, dragging]);

    const formatSave = (event) => {
        let formatted_deck_sections = [];

        for (const section_name in decks) {
            if (!["mainboard", "sideboard", "draftboard"].includes(section_name)) {
                // other data is metadata
                continue
            }
            let board = {
                "board_name": section_name,
                "cards": []
            }
            for (const col in decks[section_name]) {
                for (const card in decks[section_name][col]) {
                    let formatted_card = {
                        "scryfall_id": decks[section_name][col][card]["id"],
                        "name": decks[section_name][col][card]["name"],
                        "column": Number(col)
                    }
                    board["cards"].push(formatted_card)
                }
            }
            formatted_deck_sections.push(board)
        }

        let formatted_commander = {
            "scryfall_id": commander["id"],
            "name": commander["name"],
            "column": Number(0)
        }

        let save_object = {
            "deck_name": deckName,
            "format": format,
            "colors": colors,
            "commander": formatted_commander,
            "sections": formatted_deck_sections

        }
        // Let's send the saved object to the backend
        axios({ "method": "post",
                "url": "/api/addDeck",
                "data": save_object,
                "headers": "Content-Type: application/json"
            }).then((response) => {
                console.log(response)
                if (response.data.Code === 200) {
                    // Update alerts to show user
                    let shortname = response.data.Message;
                    dispatch(setShowAlert(true));
                    dispatch(setAlertMsg("Deck has been succesfully saved with shortname: " + shortname))
                    dispatch(setAlertType("success"));
                }
                else {
                    dispatch(setShowAlert(true));
                    dispatch(setAlertMsg("Failed to save deck with error code: " + response.data.Code))
                    dispatch(setAlertType("error"));

                }
        }).catch((resp) => {
            dispatch(setShowAlert(true));
            dispatch(setAlertMsg("Failed to save deck to database. Please try again."))
            dispatch(setAlertType("error"));
        })    
    };

    const formatUpdate = (event) => {
        let formatted_deck_sections = [];

        for (const section_name in decks) {
            if (!["mainboard", "sideboard", "draftboard"].includes(section_name)) {
                // other data is metadata
                continue
            }
            let board = {
                "board_name": section_name,
                "cards": []
            }
            for (const col in decks[section_name]) {
                for (const card in decks[section_name][col]) {
                    let formatted_card = {
                        "scryfall_id": decks[section_name][col][card]["id"],
                        "name": decks[section_name][col][card]["name"],
                        "column": Number(col)
                    }
                    board["cards"].push(formatted_card)
                }
            }
            formatted_deck_sections.push(board)
        }

        let formatted_commander = {
            "scryfall_id": commander["id"],
            "name": commander["name"],
            "column": Number(0)
        }

        let save_object = {
            "deck_name": deckName,
            "format": format,
            "colors": colors,
            "commander": formatted_commander,
            "sections": formatted_deck_sections
        }

        // Let's send the saved object to the backend
        axios({ "method": "post",
                "url": "/api/updateDeck?urlname=" + props.shortname,
                "data": save_object,
                "headers": "Content-Type: application/json"
            }).then((response) => {
            if (response.data.Code === 200) {
                dispatch(setShowAlert(true));
                dispatch(setAlertMsg("Updated deck with shortname: " + props.shortname))
                dispatch(setAlertType("success"));
            }
            else {
                dispatch(setShowAlert(true));
                dispatch(setAlertMsg("Failed to update deck with error code: " + response.data.Code))
                dispatch(setAlertType("error"));
            }
        }).catch((resp) => {
            console.log("here")
            dispatch(setShowAlert(true));
            dispatch(setAlertMsg("Failed to update. Please try again."))
            dispatch(setAlertType("error"));
        })           
    };

    return (
        <div
            data-disableselect={true}
            className={styles.wrapper}
        >
            <DragSelection/>
            <div className={`${styles.panel} ${styles.sidePanelMobile}`} >
                <Panel 
                    hoveredCard={props.hoveredCard}
                />
            </div>

            <div className={`${styles.rightSide} ${styles.rightSideMobile}`}>
                <div>
                    <MetaData setHoveredCard={props.setHoveredCard}></MetaData>
                    <Toolbar
                        formatSave={formatSave}
                        formatUpdate={formatUpdate}
                        shortname={props.shortname}
                    />
                    <div
                        className={styles.container}       
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                        tabIndex={0}
                        id="elements-container"
                        ref={elementsContainerRef}
                        data-disableselect={false}
                    >
                        {decks.mainboard.map((item, index) => (
                            <Droppable droppableId={`mainboard-column-${index}`} key={index}>
                                {provided => (
                                    <Column provided={provided} innerRef={provided.innerRef}>
                                        {item.map((card, columnIndex) => (
                                            <Draggable draggableId={`mainboard-${index}|${columnIndex}`} index={columnIndex}  key={columnIndex}>
                                                {(provided) => (
                                                    <MagicCard
                                                        key={`mainboard-${index}|${columnIndex}`}
                                                        provided={provided}
                                                        innerRef={provided.innerRef}
                                                        data={card}
                                                        selected={dragging.cardBatch.includes(`mainboard-${index}|${columnIndex}`)}
                                                        invisible={dragging.dragStack.includes(`mainboard-${index}|${columnIndex}`)}
                                                        primary={dragging.primaryCard===`mainboard-${index}|${columnIndex}`}
                                                        setHoveredCard={props.setHoveredCard}
                                                        board_name="mainboard"
                                                        column={index}
                                                        columnPosition={columnIndex}
                                                    />
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Column>
                                )}
                            </Droppable>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

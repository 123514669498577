import { configureStore } from '@reduxjs/toolkit';

// Import your reducers
import loadingSlice from './loadingSlice';
import deckListReducer from './deckListSlice';
import draggingCardsReducer from "./draggingCardsSlice"
import alertReducer from './alertSlice';
import deckMetaDataReducer from './deckMetaDataSlice';

// Create the Redux store
const store = configureStore({
    reducer: {
      loading: loadingSlice,
      decks: deckListReducer,
      dragging: draggingCardsReducer,
      alerts: alertReducer,
      deckMetaData: deckMetaDataReducer,
      // Add more reducers if needed
    },
  }
);

export default store;

import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';


export function TcgplayerGuide()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px'}} className={styles.textbody}>
                        <br></br>
                        <h1>Selling on TCGPlayer in 2024</h1>
                        <h3>Published: July. 21st 2024 </h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Guide" color="purple" />
                        <br></br>
                        <br></br>
                        Hey folks,
                        <br></br>
                        <br></br>
                        At the end of the day, Magic: The Gathering is a hobby that costs money. I've paid my fair share for event fees, bought a guilty number of boxes and cracked them, and have purchased singles abundantly.
                        <br></br>
                        <br></br>
                        After collecting this way for a while, our wallets might cry out, or our consciences might suddenly manifest themselves as Eldrazi spawn in our minds, but worry not, we are all indeed all Goldspans, sitting on a gold-mine of wealth right below our noses. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/a/7/a7cec498-e2ac-4ca0-9aa2-ef98ba634c32.jpg?1640249476"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        The loose singles from that draft you did last week and the remaining exoskeleton of the commander deck you took apart last month are ripe for regrowth.
                        <br></br>
                        <br></br>
                        Before you swipe the most powerful Magic Card, your credit card, consider selling some of your unused collection off. 
                        <br></br>
                        <br></br>
                        You might retort, "But Andy, these are all $2-5 cards - I'll never be able to find a buyer, and the guys at the LGS have already been through my binder a half dozen times!"
                        <br></br>
                        <br></br>
                        Well my friend, luckily for you, you live in the age of the internet, a time where the whole population of the United States market is your Bazaar of Baghdad.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/f/f/ff37b863-f8c4-4584-8cc2-ac0e096e583f.jpg?1562943098"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        But then you might say, "I don't want to learn how to sell online, shipping is a hassle, taxes and fees are a hassle, and I don't have the time!"
                        <br></br>
                        <br></br> 
                        Well my friend, luckily for you, you've come across my guide on how to become a level 4 seller in 21 days on TCGPlayer. And it's written by someone just like you, who knew nothing 21 days ago. 
                        <br></br>
                        <br></br>
                        <h4>Prerequisites </h4>
                        <ul>
                            <li> need to live in the United States </li>
                            <li> need to be 18 + </li>
                            <li> need to know your tax payer id </li> 
                            <li> need stamps, envelopes, a pen, a box of top loaders($12 for 100 on Amazon), and a printer </li> 
                            <li> need cards to sell </li>
                        </ul>
                        <h4> Fees Information </h4>
                        <ul>
                            <li> tcgplayer takes a flat 10% off of your sales total (selling super cheap cards is not a great idea, you could end up losing money after fees) </li>
                                <ul>
                                    <li> For cards less than 50 cents, don't bother. You will get tcgplayer fees close to 50 cents since the shipping fee is included in fee calculation</li>
                                </ul>
                            <li> for tax year 2024, as long as your sales are less than $5000 USD, you don't have to report to government </li>
                            <li> if the IRS gets their act together, starting tax year 2025, as long as you sell less than $600 USD, you don't have to report to government </li> 
                        </ul>

                        <h4> Getting Started</h4>
                        Alright let’s jump right into it! Selling on TCGPlayer is as easy as setting up an account and listing your cards. For the most part, the entire selling process is self-service. 
                        <br></br>
                        <br></br>
                        The first thing you will want to do after creating an account is fill in your personal information + tax information in the <b>My Details</b> section, including the <b>W-9 Tax Information</b>. You will only get sent a W-9 if you sell over the threshold, which is $5000 for 2024 and $600 for 2025 onwards.
                        <br></br>
                        <br></br>  
                        Afterwards, we can begin listing cards in the <b>Inventory section</b>. I did this with my binder in front of me, just looking up card by card and making sure I selected the right version of the card. I would recommend against “just eyeing” the card to figure out which version it is; instead try to use the card number/ set letters on the bottom left of each card for identification. Remember that a dot means non-foil and a star means foil, in case you are having trouble telling when the card is in a sleeve. Also know that The List cards have the same number + set number but are a different listing on TCGPlayer .
                        <br></br>
                        <br></br>
                        For price selection, know that although you have the entire US marketplace at your disposal, you are also competing with the entire country's sellers. This means that we will be using TCG-Low for our sales price. At the beginning of your selling career, I would definitely recommend just selling at TCG-Low to get the early feedback to level up your account and start getting positive feedback for credibility. For new accounts, TCGPlayer automatically makes the shipping fee $0.99 for sales above $5.00 and $1.27 for sales below $5. Buyers will see prices sorted by combined card cost + shipping fee.
                        <br></br>
                        <br></br>
                        Later on (after you have made some sales already), we need to have to better understand how customers select cards to understand how to price better. To sum it up, buyers will be trying to optimize their carts to pay as little combined shipping fees as possible, so selling at TCG-Low is not necessarily the best strategy once you reach certain levels of volume since buyers’ cart-optimizer will select your cards once it takes into account that multiple instances of shipping fees won’t need to be paid if multiple cards can be grabbed from your one store.
                        <br></br>
                        <br></br>
                        <h4> Packaging </h4>
                        Alright, after you have listed cards at TCG-Low, I waited two days before I got my first order. So how do we pack a card? Well it’s really easy actually. For singles, I just follow these steps:
                        <ul>
                            <li>Single (or double for expensive cards) sleeve the card and put it in a top-loader, the hard plastic tiles</li>
                            
                            <li>Print out the shipping sheet from the order details page</li>
                            
                            <li>Get an envelope, write your address on the top left, their address in the middle, and add a forever stamp to the top right</li>
                            
                            <li>Fold your top loader into the shipping sheet in two horizontal folds</li>
                            
                            <li>Seal the envelope, careful not to add to much water if using on-wet adhesive</li>
                            
                            <li>Drop off at your local post office or those large blue mailboxes (avoid if its raining)</li>

                            <li>Mark the card as shipped in TCGPlayer</li>
                            <ul>
                                <li>For cards less than $20, feel free to ship without tracking</li>
                                <li>For cards greater than $20, you should consider paying for tracking. This costs $4.40, not including the stamp at USPS. </li>
                            </ul>

                        </ul>
                        I’ve skipped it for cards as high as $25, but just know that if it does “disappear” in the mail, the buyer will generally win the dispute if you didn’t add tracking.
                        <br></br>
                        <br></br>

                        <h4>Daily Work</h4>
                        Now that you have a store set-up, you should get into a schedule of doing the following things:
                        <ul>
                            <li> Reviewing your prices in the <b>Inventory - Review Prices</b> section and making sure they don’t fall too far from market-low.</li>
                            <li> Checking your orders, packing them, and marking them as shipped after you drop off. Stay organized!</li>
                            <li> Checking messages from sellers in case there is an issue or a question. In general responding quickly will help get better feedback even if you mess up.</li>
                        </ul>
                        Once you’ve completed two sales and received feedback (6 days after sending out; if the seller doesn’t say anything, it's automatically converted to completed), you’ll move to seller level 2, 11 orders for seller level 3, and 55 orders for seller level 4. Seller levels just increase the maximum number of items you can list.
                        <br></br>
                        <br></br>
                        Using this method, I was able to cash out a lot of what I consider “junk” in my binder. In 65 sales, I made just over $400 that I’ll be putting into a new deck (keep your eyes out for my August article). That beats taking money out of the paycheck!
                        <br></br>
                        <br></br>
                        My only regret with this process is that there are some cards I sold that I later on realized I might need. When choosing which cards to sell, just keep in mind that Magic is a 30 year old game with very diverse playstyles. Cards that you might never play with by virtue of what formats you play might be quite good in other formats. 
                        <br></br>
                        <br></br>
                        And that’s pretty much it! It’s as easy as 1, 2. It might seem daunting at first, but the whole system is built to be user-friendly and ends up being worth the effort. As long as you sell less than the hobby threshold for taxing, then getting 90% of the value of your cards back is pretty decent compared to the smothering tithe of 50-70% value from vendors at a magic convention. Also, once you get the whole system set-up, you can sell easily in the future.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/2/4/24921817-36fe-4a2d-86e7-d3749bb8da32.jpg?1660636728"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        Hope this article helped you save money on one of the best hobbies. 
                        <br></br>
                        <br></br>
                        -xTaq
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};
import React, { useEffect, useState } from "react";
import { Carousel } from 'antd';
import CommandZone from "./CommandZone";
import styles from "./MetaData.module.css"
import { Typography } from 'antd';
import Banner from "./Banner";
import { useSelector, useDispatch } from 'react-redux';


const contentStyle = {
    height: '320px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    marginTop: "2rem",
    display: 'flex',
    boxShadow: '2px 4px 12px #00000014',
    borderRadius: "10px",
    backgroundColor: "rgb(251,247,244)",
    background: "linear-gradient(109.6deg, rgb(78, 112, 157) 11.2%, rgb(137, 164, 199) 65%, rgb(205, 213, 224) 100.2%)"
};

export default function MetaData(props) {
    // State Controllers
    let deckMetaData = useSelector((state) => state.deckMetaData);
    let format = deckMetaData.format

    return (
        <div>
            <Carousel effect="fade">
                <div>
                    <div style={contentStyle}>
                        <div className={styles.carouselDiv}>
                            { format==="Commander" && <CommandZone setHoveredCard={props.setHoveredCard}/>}
                            <div className={styles.metaDataContainer}>
                                <div className={styles.deckCode}>
                                    <Typography.Title
                                        style={{ color: 'white' }}
                                        level={3}
                                    >
                                        Deck Code Here
                                    </Typography.Title>
                                </div>
                                <div className={styles.primer}>
                                    <Banner></Banner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={contentStyle}>
                        <div className={styles.carouselDiv}>
                            <div>
                                <Typography>Deck Notes & Primer</Typography>
                                Text Here
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={contentStyle}>
                        <div className={styles.carouselDiv}>
                            <div>
                                <Typography>Deck MetaData</Typography>
                                Text Here
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={contentStyle}>
                        <div className={styles.carouselDiv}>
                            <div>
                                <Typography>Sideboard Guide</Typography>
                                Text Here
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    );
}



import styles from "./Toolbar.module.css"
import { Button} from 'antd';
import CardLookup from "./CardLookup";
import DefaultSortOrder from "./DefaultSortOrder"
import DisplayStyle from "./DisplayStyle";
import { CloudUploadOutlined, CopyOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {setShowAlert, setAlertMsg, setAlertType} from "../redux/alertSlice";


function Toolbar(props) {
    const dispatch = useDispatch();
    const decks = useSelector((state) => state.decks);

    const copyDeckToClipboard = () => {
        if (navigator.clipboard) {  // Not supported in all browsers, but chrome supports.
        // First get the current deck store into text
        console.log(decks);
        // We have to combine multi-cards
        let full_cards = {}
        for (let col=0; col<decks["mainboard"].length; col++) {
            for (let card=0; card < decks["mainboard"][0].length; card++) {
                let cardDict = decks["mainboard"][col][card];
                if (cardDict == undefined) {
                    continue
                }
                let name = cardDict["name"];
                if (name in full_cards) {
                    full_cards[name] += 1
                }
                else {
                    full_cards[name] = 1
                }
            }
        }
        // let's sort the full_cards
        const ordered_full_cards = Object.keys(full_cards).sort().reduce(
            (obj, key) => { 
              obj[key] = full_cards[key]; 
              return obj;
            }, 
            {}
          );
        // Now let's create the clipboard text
        let clipBoardText = "";
        for (const cardName in ordered_full_cards) {
            clipBoardText += ordered_full_cards[cardName] + " " + cardName + "\n";
        }
        // Push text to the clipboard!
        navigator.clipboard.writeText(clipBoardText);
        }
        // Push Notification to top of page
        dispatch(setShowAlert(true));
        dispatch(setAlertMsg("Deck copied to clipboard!"));
        dispatch(setAlertType("success"));
    }
    return (
        <div>
                <div className={styles.toolbar}>
                    <CardLookup className={styles.toolbarElement} />
                        <div className={styles.toolbarDiv} >
                            <Button
                                type="primray"
                                shape="round"
                                onClick={copyDeckToClipboard}
                                icon={<CopyOutlined />}
                                className={styles.clipBoardButton}
                            />
                            <Button
                                type="primary"
                                shape="round"
                                onClick={props.formatSave} 
                                icon={<CloudUploadOutlined/>}
                            />
                            <DefaultSortOrder />
                            <DisplayStyle />
                            {props.shortname && 
                                <Button type="primary" shape="round" onClick={props.formatUpdate} > Update </Button>
                            }
                        </div>
                </div>
        </div>
    );
  }
  
export default Toolbar;




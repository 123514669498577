import {
    createSlice,
} from '@reduxjs/toolkit'
  
  
  // Define initial state
  const initialState = {
    deckName: "",
    format: "Commander",
    colors: "",
    commander: {},
  };

  const deckMetaDataSlice = createSlice({
    name: "deckMetaData",
    initialState,
    reducers: {
      setDeckName(state, action) {
        return {
          ...state,
          deckName: action.payload
        }
      },
      setFormat(state, action) {
        return {
          ...state,
          format: action.payload
        }
      },
      setColors(state, action) {
        return {
          ...state,
          colors: action.payload
        }
      },
      setCommander(state, action) {
        return {
          ...state,
          commander: action.payload
        }
      }
     },

  })
  
  
  export const {
    setDeckName,
    setFormat,
    setColors,
    setCommander,
  } = deckMetaDataSlice.actions
  
  export default deckMetaDataSlice.reducer
  
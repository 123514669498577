import { AutoComplete } from "antd";
import React, { useState } from 'react';
import { potentialAutocompleteMatches, potentialCommanderAutocompleteMatches } from "../controllers/ScryfallController";
import { useSelector, useDispatch } from 'react-redux';
import { getCardByName } from "../controllers/ScryfallController";
import {setCommander} from "../redux/deckMetaDataSlice";
import { setMainboard } from "../redux/deckListSlice";
import cloneDeep from 'lodash/cloneDeep';

function CardLookup(props) {

  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [catalog, setCatalog] = useState([]);


  const decks = useSelector((state) => state.decks);
  const dispatch = useDispatch();


  const getCatalog = async (searchText) => {
    let catalog = {"data": null};
    if (props["commandZone"] === true) {
      // We have to use /search instead of /autocomplete
      catalog = await potentialCommanderAutocompleteMatches(searchText);
      // extract the names
      let names_list = [];
      for (let i =0; i < catalog.data.length; i++) {
        names_list.push(catalog.data[i].name)
      }
      catalog = {"data": names_list};
    }
    else {
      catalog = await potentialAutocompleteMatches(searchText);
    }

    if (catalog.data === null){
        setCatalog([])
    }
    else{

        let array = []
        for (let i = 0; i< catalog.data.length; i++){
            let object = {
                value: catalog.data[i]
            }
            array.push(object)
        }
        setCatalog(array)
    }
    
    return;
  };

  const onSelect = (selectedValue) => {
    getCardByName(selectedValue)
      .then((card) => {
        // Manually duplicate the object, spreading leads to issues
        let duplicate = cloneDeep(decks.mainboard)
        duplicate[0].push(card)
        // use props to determine where to push card
        if (props["commandZone"] === true) {
          dispatch(setCommander(card));
          // close the modal
          props["closeModal"](false);
        }
        else {
          dispatch(setMainboard(duplicate))
        }
      })
      .catch((error) => {
        console.error('Error fetching card:', error);
      });
  };

  const onChange = (selectedValue) => {
    setValue(selectedValue);
  };

  const getPanelValue = (searchText) => {
    if (!searchText) {
      return [];
    }
    getCatalog(searchText)
    return catalog

  };
  

  return (
    <>
      <AutoComplete
        options={options}
        style={{ width: 250 }}
        onSelect={onSelect}
        onSearch={(text) => {
            setOptions(getPanelValue(text))
        }}
        placeholder="Lookup a card"
        value={value}
        onChange={onChange}
      />
    </>
  );
}

export default CardLookup;

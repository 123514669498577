import React, { useState, useRef, useEffect } from "react";
import styles from "./Card.module.css";
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { SettingFilled, CloseOutlined, CopyOutlined} from '@ant-design/icons';
import { Button, Modal, Image} from 'antd';
import { getCardVersionsByName } from "../controllers/ScryfallController";
import {setMainboard} from "../redux/deckListSlice";
import cloneDeep from 'lodash/cloneDeep';


export default function MagicCard(props) {

  const dispatch = useDispatch();
  const [open, setModalOpen] = useState(false);
  const [currentCardVersions, setCurrentCardVersions] = useState([]);

  const decks_state = useSelector((state) => state.decks);
  const dragging = useSelector((state) => state.dragging);
  const { provided, innerRef, data, selected, invisible, primary, setHoveredCard, column, columnPosition } = props;

  const getCardPng = (data) => {
    if (data !== undefined) { 
      // If the card is double-sided, then the "image_uris.png" is in a different json directory
      if ("image_uris" in data) { // single-sided
        return data.image_uris.png
      }
      return data.card_faces[0].image_uris.png
    }
  }
  
  // need board, column, position
  const HandleCardVersionUpdate = async (newCard) => {
    // first update the board state
    let duplicate_main = cloneDeep(decks_state.mainboard);
    duplicate_main[column][columnPosition] = newCard
    dispatch(setMainboard(duplicate_main));
    // todo: Let's send the saved object to the backend

    // finally, close the modal
    setModalOpen(false);
  }

  const handleCardRemoval = async () => {
    // make a copy, then remove the index from it
    let duplicate_main = cloneDeep(decks_state.mainboard);
    duplicate_main[props.column].splice(props.columnPosition, 1);
    dispatch(setMainboard(duplicate_main));
  }

  const handleCardCopy = async () => {
    // make a copy, then put the same card at the bottom
    let duplicate_main = cloneDeep(decks_state.mainboard);
    let target_card = duplicate_main[props.column][props.columnPosition];
    duplicate_main[props.column].push(target_card);
    dispatch(setMainboard(duplicate_main));
  }

  const handleCardSettingsClicked = async (event) => {
      // make api request to get all names
      // const { foo, bar }  = await iAmAPromise.then(result => result.data);
      let results = await getCardVersionsByName(event.name).then((results) => {
        setCurrentCardVersions(results)
        setModalOpen(true)
      })   
  }

  let displayStyle = "Visual Stacks"
  if (decks_state !== undefined) {
    displayStyle = decks_state.displayStyle;

  }
  const classList = classNames(
    styles.parentContainer,
    (selected ? styles.highlighted : ""),
    (invisible ? styles.opaque : ""),
    (primary ? styles.primary : "")
  );

  if (data === undefined) {
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={innerRef}
      />
    );
  }

  const getVersionCards = () => { 
    if (currentCardVersions === undefined && currentCardVersions.data === undefined) {
    }
    else {
      if (currentCardVersions.data !== undefined) {
       let rows = currentCardVersions.data.forEach( (element, index) => {
          <div className="cardImage" key={index}> 
          <Image
            key={index}
            src={getCardPng(element)}
            preview={false}
            className={styles.card}
            onMouseEnter={()=>{
              setHoveredCard(data)
            }}
          />
        </div>
        })
        return rows
      }
    }
  }


  if (displayStyle === "Visual Stacks") {
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={innerRef}
        className={classList}
      >
        <Modal
          title={data.name}
          centered
          open={open}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          className={styles.settingsModal}
          width={"30%"}
          height={"80%"}
        >
        <p>Current Version: {data.set.toUpperCase()} {data.multiverse_ids[0]}</p>
        <hr></hr>
        { currentCardVersions.data ?     
          currentCardVersions.data.map((item, index) => (
            <div>
              <div className={styles.versionSelector}>
                <div className={styles.versionCardImage}> 
                  <Image
                    src={getCardPng(item)}
                    preview={false}
                    key={item.id}
                    className={styles.card}
                    onMouseEnter={()=>{
                      setHoveredCard(item)
                    }}
                  />
                </div>
                  <div className={styles.versionInfo}>
                    {item.set.toUpperCase()}
                  </div>
                  <div className={styles.updateVersionButton}>
                      <Button onClick={() => HandleCardVersionUpdate(item)}>
                        Use this version 
                      </Button>
                  </div>
              </div>
              
            <hr></hr>
            </div>
          ))
          
          :
          <div></div>
        }


        </Modal>
        <div className={styles.cardImage}> 
          <Image
            src={getCardPng(data)}
            preview={false}
            className={selected? styles.highlightedCard : styles.card}
            onMouseEnter={()=>{
              setHoveredCard(data)
            }}
          />
        </div>
        <div className={styles.buttonsBar}>
          {/* Button to Open up Remove Card*/}
          <div className={styles.removeCardButton}
            onClick={()=>{
              handleCardRemoval()
            }}>
            <CloseOutlined style={{ fontSize: '20px'}}/>
          </div>

          {/* Button to Duplicate Card*/}
          <div className={styles.copyCardButton}
            onClick={()=>{
              handleCardCopy()
            }}>
            <CopyOutlined style={{ fontSize: '20px'}}/>
            B
          </div>

          {/* Button to Open up Card Versions Modal*/}
          <div className={styles.cardDetailButton}
            onClick={()=>{
              handleCardSettingsClicked(data)
            }}>
            <SettingFilled style={{ fontSize: '20px'}}/>
          </div>
        </div>
      </div>
    );
  }

  if (displayStyle === "Visual Grid") {
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={innerRef}
        className={classList}
      >
        <Image
          src={data?.image_uris.png}
          preview={false}
          className={styles.card}
          onMouseEnter={() => {
            setHoveredCard(data);
          }}
        />
      </div>
    );
  }
  if (displayStyle === "Condensed Text") {
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={innerRef}
        className={classList}
      >
        {data?.name}
      </div>
    );
  }
}

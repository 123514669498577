
import React, { useState } from "react";
import { Button, Dropdown } from 'antd';
import { setDisplayStyle, sortMainboardByManaValue, sortMainbordByColor } from '../redux/deckListSlice';
import { useSelector, useDispatch } from 'react-redux';
import styles from "./DisplayStyle.module.css"

function DisplayStyle() {
    const decks = useSelector((state) => state.decks);
    let displayStyle = decks.displayStyle
    const dispatch = useDispatch();

    const items = [
        {
            key: '1',
            label: (
                <div onClick={()=>{
                    dispatch(setDisplayStyle("Visual Stacks"))
                }}>
                    Visual Stacks
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div onClick={()=>{
                    dispatch(setDisplayStyle("Visual Grid"))
                }}>
                    Visual Grid
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div onClick={()=>{
                    dispatch(setDisplayStyle("Condensed Text"))
                }}>
                    Condensed Text
                </div>
            ),
        },
    ];
    return (
        <Dropdown
            menu={{items,}}
            placement="bottom"
            arrow={{pointAtCenter: true}}
        >
            <Button className={styles.displayButton}>{displayStyle}</Button>
        </Dropdown>
      );
  }
  
  export default DisplayStyle;
  
import React, { useEffect, useState } from "react";
import  styles from './Column.module.css'; // Import the CSS file

class Column extends React.Component {
    
    render() {
      const { provided, innerRef, children } = this.props;
      return (
        <div {...provided.droppableProps} ref={innerRef} className={styles.container}>
          {children}
        </div>
      );
    }
  }

export default Column
import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';
import packing from "./assets/command_fest_atlanta/packing.jpg";
import duals from "./assets/command_fest_atlanta/duals.jpg";
import artists from "./assets/command_fest_atlanta/artists.jpg";
import seating from "./assets/command_fest_atlanta/seating.jpg";
import city from "./assets/command_fest_atlanta/city.jpg";
import command_fest_tics from "./assets/command_fest_atlanta/command_fest_tics.jfif";

export function GolgariRampStandard()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px'}} className={styles.textbody}>
                        <br></br>
                        <h1>Golgari Ramp -  Paper Standard is Back!</h1>
                        <h3>Published: August 31st 2024 </h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Standard" color="green"/>
                        <br></br>
                        <br></br>  
                        <h4>Setting the Scene</h4>                      
                        Since standard rotated on July 26th, 2024 with the advent of Bloomburrow, standard interest in the paper scene has been steadily rising! There are many factors that we can attribute to this:
                        <ul>
                            <li> The rotation removed or neutered previously oppressive decks </li>
                            <li> An influx of new players resulting from Bloomburorw’s natural popularity </li>
                            <li> A very lucrative opportunity to win a $300 full art Urza’s Saga in the WotC sponsored Standard Store Championships, joined by popular Cowboy Bebop Promos that are promised to be drip-released for the coming seasons</li>
                            <li> The Magic Spotlight Series being announced as a global competitive arena for Standard Constructed</li>
                        </ul>
                        Whatever the case is, I’m happy to see the excitement for a previously impoverished format!
                        <br></br>
                        <br></br>
                        Since covid, it looked like Magic Arena was going to dominate the standard scene for perpetuity, which does have its advantages, but long gone were the days where players competed under the camera in the limelight for glory and fame (or just sizable prize pools). 
                        <br></br>
                        <br></br>
                        When I first began playing Magic in my school days, caw-blade was the bread and butter of Magic news and Jace the Mind Sculptor was eating up the format. Many rotations have come and gone since then, but Standard has historically always had a special place in the Magic world. 
                        <br></br>
                        <br></br>
                        At my own LGS, we saw a turnout of nearly 8 players for the last three weekly standard nights! 
                        <br></br>
                        <br></br>
                        This may not sound like a lot, but other standard constructed events at our store have been struggling to gain traction - pioneer, modern, and pauper have often fired or barely fired with just 4 participants. Our FNM Drafts have consistently had 12-20 people on the other hand. 
                        <br></br>
                        <br></br>
                        <h4>Making my Own Brew</h4>
                        Energy is infectious - inspired and wanting to brew a deck outside of the commander format, I decided to try my hand at brewing a deck in this new frontier. Here is a base deck list based on the cards I have, but it is still a work in progress:
                        <br></br>
                        <br></br>
                        <a href="https://sorinslist.com/deck/DromokaLhurgoyfSorcery">The decklist</a>
                        <br></br>
                        <br></br>
                        Golgari midrange has been popular online with both a creature variant and a planeswalker-ult’ing variant, but I wanted to take advantage of a card that echoes another format-defining card: 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/7/e7fbb489-e2b5-4278-8162-86802cf124d8.jpg?1712860610"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/2/8/286db2ad-ee23-4aeb-99f8-6786611264bb.jpg?1643830471"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <br></br>
                        Like collected company, the strength of Smuggler's Surprise comes from using it at instant speed. 
                        <br></br>
                        Most cards that bring creatures back from the graveyard are only sorcery speed - and for good reason.
                        <br></br>
                        Being able to play creatures at instant speed allows us to make incredibly favorable blocks simply because our opponents will have difficulty choosing attackers when they can't see our blockers.
                        <br></br>
                        <br></br>
                        I’ve come across several decks that utilize Smuggler's Surprise, all looking to cheat out powerful creatures at instant speed.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/7/2/72e805e9-69be-45c1-aa04-f460641a0c1e.jpg?1699044395"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/c/9c10934d-9016-43c4-a7ab-56cc7d8f671f.jpg?1682206592"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <br></br>
                        Here is a mono-green version that uses Ghalta with Goreclaw to drop a full hand of hasty dudes and win in one turn. 
                        <br></br>

                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/0/904ff94a-4db4-44a6-8593-89c32905b3fc.jpg?1712355862"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/7/e7a70f5a-2056-4c26-b6ea-9f751b5d0d8c.jpg?1712355721"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <br></br>
                        Here's another Gruul variant that tries to play multiple Terror of the Peaks.
                        <br></br>
                        <br></br>
                        I set my sights on an even more terrifying game - Vaultborn Terror.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/6/2/62b3f560-262b-4bc3-9aef-535fd7082c28.jpg?1712352885"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/6/2/62b3f560-262b-4bc3-9aef-535fd7082c28.jpg?1712352885"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <br></br>
                        Specifically two of them ;) If they enter together, that results in 4 triggers, totalling 12 life and 4 card draws.
                        <br></br>
                        This is very similar to casting Atraxa, but a little stickier since removing any one of them with a destroy spell results in two more draw triggers and a replacement dinosaur.
                        <br></br>
                        <br></br>
                        <h4>The Gameplan</h4>
                        It should be a priority for this deck to ramp to 6 or 8 mana, the key points at which Smuggler's Surprise can use the second mode exclusively, or the first and second modes together. 
                        <br></br>
                        <br></br>
                        With the win condition determined, the rest of the deck focuses on ramping and removal to stay alive. As always, black has no shortage of removal spells, from Cut Down's to Bitter Triumphs.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/9/99255a66-b868-45fc-a2a9-0c89bd851b69.jpg?1699044198"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/b/3b5349db-0e0a-4b15-886e-0db403ef49cb.jpg?1721426825"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;  
                        <br></br>
                        For ramp, I borrow from domain ramp. I use Tarrian’s Journal in conjunction with Heaped Harvest to explosively get two lands in one turn.
                        <br></br>
                        <br></br>
                        I chose to utilize cards that make artifacts on entrance to feed the journal for nearly free card draws, while focusing on stabilizing until we can get our dinosaur out.
                        <br></br>
                        <br></br>
                        The dinosaur also relies on creatures with power 4 or greater, so Hostile Investigator is a good value engine that synergizes with the rest of our deck for that. 
                        <br></br>
                        Glimpse the seedcore is also a good tool to ramp starting on turn two. 
                        <br></br>
                        <br></br>
                        One of the key issues with crafting a deck like this is that it fails to go “under” like aggro decks including mono-red prowess and Rakdos lizards, while often failing to go “over” more control decks that have win cons like Jace, the Perfected Mind loops, and Atraxa, Grand Unifier.
                        <br></br>
                        <br></br>
                        Still, with some sideboarding, I think it stands a chance at the weekly local tournament, which is kind of what I’m happy to be a part of! If I were gunning for first place however, I would probably choose something closer to Atraxa Domain. 
                        <br></br>
                        <br></br>
                        <h4>Conclusion</h4>
                        Home brews like mine, although not perfect by any measure, are just one example of the possibilities that the standard format revival is bringing!
                        <br></br>
                        <br></br>
                        A combination of WotC sponsored events with Magic's core, inherently complex gameplay and deckbuilding sets the stage for a new generation of magic players post-Bloomburrow.
                        <br></br>
                        <br></br>
                        Anyways, cheers to the revival of Paper Standard and happy brewing! Duskmourn is just around the corner.
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};

function sortAllColumnsByCMC(board) {

  let rows = [[],[],[],[],[],[],[],[]]

  for (let i = 0; i < board.length; i++) {
    const innerArray = board[i];
    for (let j = 0; j < innerArray.length; j++) {
      const element = innerArray[j];
      
      let position = element.cmc
      if (element.cmc >= 7){
        position = 7
      }
      rows[position].push(element)
    }
  }
  return rows
}

function sortAllColumnsByColor(board) {
  let rows = [[],[],[],[],[],[],[],[]]
  for (let i = 0; i < board.length; i++) {
    const innerArray = board[i];
    for (let j = 0; j < innerArray.length; j++) {
      const element = innerArray[j];

      let colorArray = [...element.colors]
      let typeLine = element.type_line
      let position = determineRowPositionByColor(colorArray, typeLine)
      rows[position].push(element)
    }
  }
  return rows
}
function sortAllColumnsByRarity(board) {
  let rows = [[],[],[],[],[],[],[],[]]
  for (let i = 0; i < board.length; i++) {
    const innerArray = board[i];
    for (let j = 0; j < innerArray.length; j++) {
      const element = innerArray[j];

      let rarity = element.rarity
      let position = determineRowPositionByRarity(rarity)
      rows[position].push(element)
    }
  }
  return rows
}

function determineRowPositionByColor(color, typeLine) {
  if (color.length === 1) {
    switch (color[0]) {
      case 'W': return 0;
      case 'U': return 1;
      case 'B': return 2;
      case 'R': return 3;
      case 'G': return 4;
      default: return 8
    }
  } else if (color.length > 1) {
    return 5;
  } else if (color.length === 0) {
    return typeLine.includes("Land") ? 7 : 6;
  }
  return 8
}

function determineRowPositionByRarity(rarity) {
  const rarityMap = {
    "mythic": 0,
    "rare": 1,
    "uncommon": 2,
    "common": 3,
  };
  if (rarity in rarityMap) {
    return rarityMap[rarity];
  }
  return 4;
}

function sortByCMC(arr) {
    // Custom comparison function to sort by the 'cmc' attribute of objects
    function compareCmc(a, b) {
        return a.cmc - b.cmc;
    }

    // Sort the array using the compareCmc function
    arr.sort(compareCmc);
}

function getCardDraggableId(arr) {

    // Gives back an array of 
    // [00, 01, 10]
    // Note the draggable id is not going to be 0|1, but 01
    let result = [];
    for (let str of arr) {
      if (str.length >= 2) {
        let lastThreeChars = str.slice(-3);
        let draggableId = lastThreeChars.substring(0, 1) + lastThreeChars.substring(2)
        result.push(draggableId);
      }
    }

    return result;
}


function removeItemsByIndices(lst, indices) {
    let sortedIndices = indices.sort((a, b) => {
      let [i1, j1] = a.split('');
      let [i2, j2] = b.split('');
      return j2 - j1 || i2 - i1;
    });
  
    for (let index of sortedIndices) {
      let [i, j] = index.split('').map(Number);
      if (lst[i] && lst[i][j] !== undefined) {
        lst[i].splice(j, 1);
      }
    }
}


function getObjectsByIndices(arr, indices) {
    let result = [];
    for (let index of indices) {
        let i = parseInt(index[0]);
        let j = parseInt(index[1]);
        if (arr[i] && arr[i][j]) {
        result.push(arr[i][j]);
        }
    }
    return result;
}

function insertObjectsByIndex(arr, objects, index) {
    let i = parseInt(index[0]);
    let j = parseInt(index[1]);
  
    if (arr[i]) {
      arr[i] = arr[i].slice(0, j + 1).concat(objects, arr[i].slice(j + 1));
    }
}

export {
    sortByCMC,
    sortAllColumnsByCMC,
    sortAllColumnsByColor,
    sortAllColumnsByRarity,
    getCardDraggableId,
    removeItemsByIndices,
    getObjectsByIndices,
    insertObjectsByIndex,
}


import React, { useState, useRef, useEffect } from "react";
import styles from "./CommandZone.module.css";
import { useSelector, useDispatch } from 'react-redux';
import { SettingFilled, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import {setCommander} from "../redux/deckMetaDataSlice";
import CardLookup from "./CardLookup";
import { Modal, Image, Button } from 'antd';


export default function CommandZone(props) {
    const dispatch = useDispatch();
    const { setHoveredCard } = props;
    const deck_data = useSelector((state) => state.deckMetaData);
    
    const [openCommanderModal, setCommanderModalOpen] = useState(false);


    const handleCardRemoval = async () => {
        // set commander to empty
        dispatch(setCommander({}));
      }

    const commandZoneClicked = async (event) => {
      // make api request to get all names
      // const { foo, bar }  = await iAmAPromise.then(result => result.data);
      //let results = await getCardVersionsByName(event.name).then((results) => {
      //  setCurrentCardVersions(results)
      setCommanderModalOpen(true);
      //})   
    }
  


    const getCardPng = (data) => {
        if (JSON.stringify(data) === "{}" ) {
            return ""   // empty png is fine 
        }
        if (data !== undefined) { 
          // If the card is double-sided, then the "image_uris.png" is in a different json directory
          if ("image_uris" in data) { // single-sided
            return data.image_uris.png
          }
          return data.card_faces[0].image_uris.png
        }
    }
    return (

      <React.Fragment>
        <div className={styles.commandZone} onClick={commandZoneClicked} >
            <Image
                src={getCardPng(deck_data.commander)}
                preview={false}
                className={styles.card}
                onMouseEnter={()=>{
                  setHoveredCard(deck_data.commander)
                }}
            />
        </div>
        <Modal
            title={"Commander Selection"}
            centered
            open={openCommanderModal}
            onOk={() => setCommanderModalOpen(false)}
            onCancel={() => setCommanderModalOpen(false)}
        >
            <br></br>
            <CardLookup commandZone={true} closeModal={() => setCommanderModalOpen(false)} />
        </Modal>
      </React.Fragment>


    )
}

import Mainboard from "../components/Mainbord";
import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getCardById } from "../controllers/ScryfallController";
import { setShowAlert, setAlertMsg, setAlertType} from "../redux/alertSlice"
import { setDeckName, setFormat, setColors, setCommander } from "../redux/deckMetaDataSlice";
import {setMainboard, setSideboard, setDraftboard} from "../redux/deckListSlice";
import CommandZone from "../components/CommandZone";
import styles from "./HomeScreen.module.css";
import sorinCard from "../assets/sorinDefaultCard.json";

import axios from 'axios';

function HomeScreen(props) {
  const dispatch = useDispatch();
  let { shortname } = useParams();

  const [card, setCard] = useState(sorinCard);


  const setHoveredCard = (cardObject) => {
    // Handle the emitted value in the parent component

    if (Object.keys(cardObject).length !== 0){
      setCard(cardObject)
    }
  };

  const getAndLoadDeckEditor = (event) => {
    // Let's send the saved object to the backend
    axios({ "method": "get",
    "url": "https://sorinslist.com/api/getDeck/?urlname=" + event,
    "headers": "Content-Type: application/json"
    }).then((response) => {
        if (response.data.Code === 404) {
          dispatch(setShowAlert(true));
          dispatch(setAlertMsg("Deck with shortname " + event + " not found. Please check case-sensitivity."))
          dispatch(setAlertType("error"));
          return
        }
        let response_data = response["data"]["Decklist"];

        // update state for deck metadata
        let deck_name = response_data["DeckName"];
        let format = response_data["Format"];
        let colors = response_data["Colors"];

        // call scryfall for commander
        let commander = response_data["Commander"];
        if (JSON.stringify(commander) !== '{}') {
          console.log("looking up commander!");
          let commander_query = getCardById(commander["ScryfallId"]).then((card) => {
            return card // return card data + column + board_name
          });
          Promise.all([commander_query]).then((promise_returns) => {
            dispatch(setCommander(promise_returns[0]));
          })
        }

        dispatch(setDeckName(deck_name));
        dispatch(setFormat(format));
        dispatch(setColors(colors));
        
        // begin getting decklist info
        let scryfall_promises = [];
        let backend_decklist = response_data["Sections"];

        // Loop through the response to find the card scryfall ID's
        for (let section_index in backend_decklist) {
            let board_name = backend_decklist[section_index]["BoardName"];
            let board = backend_decklist[section_index]["Cards"]
            for (let card_index in board) {
              // Create a promise that calls out to scryfall for card data
              let card_data = getCardById(board[card_index]["ScryfallId"]).then((card) => {
                return [card, board[card_index]["Column"], board_name] // return card data + column + board_name
              });
              scryfall_promises.push(card_data);
            }
        }

        /* Await all scryfall promises before updating deck store*/
        Promise.all(scryfall_promises).then((promise_returns) => {
          // We're loading a list, clear the decklist in state
          let duplicate_main = [[],[],[],[],[],[],[],[]];
          let duplicate_side = [[],[],[],[],[],[],[],[]];
          let duplicate_draft = [[],[],[],[],[],[],[],[]];
          promise_returns.map((prom) => {
            if (prom[2] === "mainboard") {
            duplicate_main[prom[1]].push(prom[0]);
            }
            else if (prom[2] === "sideboard") {
              duplicate_side[prom[1]].push(prom[0]);
            }
            else {
              duplicate_draft[prom[1]].push(prom[0]);
            }
          });
          dispatch(setMainboard(duplicate_main));
          dispatch(setSideboard(duplicate_side));
          dispatch(setDraftboard(duplicate_draft));

          return;
        })
    }).catch((error) => {
      console.log(error)
      // add alert that loading failed
      dispatch(setShowAlert(true));
      dispatch(setAlertMsg("Failed to load deck. Please check case-sensitive shortname and try again."))
      dispatch(setAlertType("error"));

      
    })
  }


  useEffect(() => {
    // If load directly into /deck/:shortname
    // Make API call
    if (shortname !== undefined) {
      getAndLoadDeckEditor(shortname)
    } 
  }, [shortname]);
  
  return (
    <div className={styles.flex}>
    {shortname === null && 
      <nav>      
        <Mainboard setHoveredCard={setHoveredCard} hoveredCard={card}></Mainboard>
      </nav>
    }
    {shortname !== null &&
      <nav>      
        <Mainboard setHoveredCard={setHoveredCard} hoveredCard={card} shortname={shortname}></Mainboard>
      </nav>
    }
    </div>
  );
    
}
export default HomeScreen; 
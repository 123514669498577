import {
    createSlice,
} from '@reduxjs/toolkit'
  
  
  // Define initial state
  const initialState = {
    primaryCard: [],
    cardBatch: [],
    dragStack: [],
  };

  const draggingCardsSlice = createSlice({
    name: "decklist",
    initialState,
    reducers: {
      setPrimaryCard(state, action) {
        return {
          ...state,
          primaryCard: action.payload
        }
      },
      setCardBatch(state, action) {
        return {
          ...state,
          cardBatch: action.payload
        }
      },
      setDragStack(state, action) {
        return {
          ...state,
          dragStack: action.payload
        }
      },
    },

  })
  
  
  export const {
    setPrimaryCard,
    setCardBatch,
    setDragStack,
  } = draggingCardsSlice.actions
  
  export default draggingCardsSlice.reducer
  
import styles from "./TextPages.module.css";
import { Card, Col, Row, Image} from 'antd';
import MagicCard from "../components/Card"

export default function DeckLoader()  {
    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <br></br>
            <h2>About</h2>
            <p>Learn more about the Sorin's List authors.</p>
            &nbsp;
            
            <Row gutter={16}>
                <Col span={8}>
                <Card title="xTaq" bordered={true}>
                    <p>Mtg combo enjoyer since the Alara Shards Block.
                       I enjoy standard & explorer on Arena and play high powered commander regularly at the local pub.</p>
                    &nbsp;
                    &nbsp;
                    <p><b>Favorite Colors: </b> Abzan </p>
                    &nbsp;
                    <p><b>Favorite Card: </b>  
                    <br></br>
                    <Image
                        src={"https://cards.scryfall.io/large/front/3/6/3668996e-659d-413b-84e6-9f3099518d7f.jpg?1682693957"}
                        preview={true}
                        height={"25vh"}
                        className={styles.card}
                    /></p>
                </Card>
                </Col>
                <Col span={8}>
                <Card title="Zenthr" bordered={false}>
                    <p>Mike's Bio Here ;)  Lines of Text & Lines of Text & Lines of Text & Lines of Text & Lines of Text
                        & Lines of Text & Lines of Text& Lines of Text </p>
                    &nbsp;
                    &nbsp;
                    <p><b>Favorite Colors: </b> </p>
                    &nbsp;
                    <p><b>Favorite Card: </b>  
                    <br></br>
                    <Image
                        src={"https://cards.scryfall.io/large/front/4/a/4a706ecf-3277-40e3-871c-4ba4ead16e20.jpg?1582053605"}
                        preview={true}
                        height={"25vh"}
                        className={styles.card}
                    /></p>
                </Card>
                </Col>
            </Row>
        </div>
    );
}
  

import React from "react";
import { Button, Dropdown } from 'antd';
import { setDefaultSortOrder, sortMainboardByManaValue, sortMainbordByColor, sortMainbordByRarity } from '../redux/deckListSlice';
import { useSelector, useDispatch } from 'react-redux';
import styles from "./DefaultSortOrder.module.css";


function DefaultSortOrder() {
    const decks = useSelector((state) => state.decks);
    let defaultSortOrder = decks.defaultSortOrder
    const dispatch = useDispatch();

    const items = [
        {
            key: '1',
            label: (
                <div onClick={()=>{
                    dispatch(setDefaultSortOrder("Mana Value"))
                    dispatch(sortMainboardByManaValue())
                }}>
                    Mana Value
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div onClick={()=>{
                    dispatch(setDefaultSortOrder("Color"))
                    dispatch(sortMainbordByColor())
                }}>
                    Color
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div onClick={()=>{
                    dispatch(setDefaultSortOrder("Rarity"))
                    dispatch(sortMainbordByRarity())
                }}>
                    Rarity
                </div>
            ),
        },
    ];
    return (
        <Dropdown
            menu={{items,}}
            placement="bottom"
            arrow={{pointAtCenter: true}}
        >
            <Button className={styles.defaultSortOrder}>{defaultSortOrder}</Button>
        </Dropdown>
      );
  }
  
  export default DefaultSortOrder;
  
import {
    createSlice,
  } from '@reduxjs/toolkit'
  
  
  // Define initial state
  const initialState = {
    showAlert: false,
    alertMsg: "default store msg",
    alertType: "success",
  };
  
  
  
  const alertSlice = createSlice({
    name: "decklist",
    initialState,
    reducers: {
      setShowAlert(state, action) {
        return {
          ...state,
          showAlert: action.payload
        }
      },
      setAlertMsg(state, action) {
        return {
          ...state,
          alertMsg: action.payload
        }
      },
      setAlertType(state, action) {
        return {
          ...state,
          alertType: action.payload
        }
      },
    },
  })
  
  
  export const  {
    setShowAlert,
    setAlertMsg,
    setAlertType,
  } = alertSlice.actions
  
  export default alertSlice.reducer
  
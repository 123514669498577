
import { useState } from 'react';
import { Card, Image, Empty } from 'antd';
import Advertisement from '../components/Advertisement';
import cardback from "../assets/Cardback.jpg"
import styles from "./Panel.module.css"
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as AlignSVG } from "../assets/align-vertical-spacing-svgrepo-com.svg";
import { ReactComponent as AddSquareSVG } from "../assets/add-square-svgrepo-com.svg";
import { ReactComponent as CardSearchSVG } from "../assets/card-search-svgrepo-com.svg";
import { ReactComponent as PriceTagSVG } from "../assets/price-tag-svgrepo-com.svg";
import { Typography } from "antd";
import { FloatButton } from 'antd';

function getCardPng(data) {
  // If the card is double-sided, then the "image_uris.png" is in a different json directory
  if ("image_uris" in data) { // single-sided
    return data.image_uris.png
  }
  else if ("card_faces" in data) {
    return data.card_faces[0].image_uris.png
  }
}    

function Panel(props) {


    const [currentTab, setCcurrentTab] = useState("Prices");

    const {hoveredCard } = props;
    var displayedCard = getCardPng(hoveredCard)


    const setUri = props.hoveredCard.set_uri
    const isEmpty = Object.keys(hoveredCard).length === 0;

    if (isEmpty) {
      displayedCard = cardback
    }

    return (
        <div className={styles.panelWrapper}>
          <div className={styles.stickyDiv}>
            <Image
              src={displayedCard}
              preview={false}
              className={styles.card}
            />
          <FloatButton.Group shape="square" className={styles.floatButton}>
                <FloatButton
                  className={styles.buttonGroupButton}
                  onClick={()=>{setCcurrentTab("Description")}}
                  icon={<AlignSVG className={styles.svg}/>}
                />
                <FloatButton
                  className={styles.buttonGroupButton}
                  onClick={()=>{setCcurrentTab("Prices")}}
                  icon={<PriceTagSVG className={styles.svg}/>}
                />
                <FloatButton
                  className={styles.buttonGroupButton}
                  onClick={()=>{setCcurrentTab("Additional")}}
                  icon={<CardSearchSVG className={styles.svg}/>}
                />
                <FloatButton
                  className={styles.buttonGroupButton}
                  onClick={()=>{setCcurrentTab("Suggestions")}}
                  icon={<AddSquareSVG className={styles.svg}/>}
                />
          </FloatButton.Group>

            {currentTab==="Description" &&
              <div className={styles.cardDescription}>
                <Typography.Title level={5}  className={styles.title}>
                  {hoveredCard?.name ? hoveredCard?.name : "" }
                </Typography.Title>
                <Typography.Text  className={styles.title}>
                  {hoveredCard?.type_line ? hoveredCard?.type_line : "" }
                </Typography.Text>
                <Typography.Paragraph className={styles.paragraph}>
                  {hoveredCard?.oracle_text ? hoveredCard?.oracle_text : "" }
                  <br/>
                </Typography.Paragraph>
                <Typography.Text className={styles.powerToughness}>
                  {hoveredCard?.power ? hoveredCard?.power + "/" : "" }{hoveredCard?.toughness ? hoveredCard?.toughness : "" }
                </Typography.Text>

              </div>
            }

            {currentTab==="Prices" &&
              <div className={styles.cardPrices}>
                <Typography.Title level={5}  className={styles.title}>
                  Prices
                </Typography.Title>
                <Typography.Paragraph className={styles.paragraph}>
                  Regular: $ {hoveredCard?.prices?.usd ? hoveredCard?.prices?.usd : "_.__"}
                  <br/>
                  Foil: $ {hoveredCard?.prices?.usd_foil ? hoveredCard?.prices?.usd_foil : "_.__" }
                </Typography.Paragraph>
              </div>
            }

            {currentTab==="Additional" &&
              <div className={styles.additionalInfo}>
                <Typography.Paragraph className={styles.paragraph}>
                  {hoveredCard?.rarity ? hoveredCard?.rarity : "" }  <br/>
                  {hoveredCard?.set ? hoveredCard?.set : "" }  <br/>
                </Typography.Paragraph>
              </div>
            }

            {currentTab==="Suggestions" &&
              <div className={styles.additionalInfo}>
                <Typography.Paragraph className={styles.paragraph}>
                  {hoveredCard?.rarity ? hoveredCard?.rarity : "" }  <br/>
                  {hoveredCard?.set ? hoveredCard?.set : "" }  <br/>
                </Typography.Paragraph>
              </div>
            }
            

          </div>
        </div>
    );
  }
  
export default Panel;




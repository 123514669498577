/*
This is the controller for all connections that go through the ScryFall API
https://scryfall.com/docs/api
*/
import { nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export function getCardById(cardId) {
  return axios.get("https://api.scryfall.com/cards/" + cardId)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching card:', error);
      throw error;
    });
}

export function getCardByName(cardName) {
  return axios.get("https://api.scryfall.com/cards/named?exact=" + cardName)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching card:', error);
      throw error;
    });
}

export function getCardVersionsByName(cardName) {
  /*Todo: implement using rollup on prints, see here:
  https://scryfall.com/docs/api/cards/search */
  return axios.get("https://api.scryfall.com/cards/search?unique=prints&q=!\"" + cardName + "\"")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching card:', error);
      throw error;
    });
}

export function getDecklistViaDeckcode(deckcode){

    console.log(deckcode)

    let deck = {}

    axios.get("https://api.scryfall.com/cards/f295b713-1d6a-43fd-910d-fb35414bf58a").then((response) => {
       console.log(response.data)
    });


    return deck
}

export const fetchCardUsingId = createAsyncThunk( 'scryfall/fetchCardId',

    async (cardId) => {
        let cardInfo = {}
        axios.get("https://api.scryfall.com/cards/" + cardId).then((response) => {
            cardInfo = response.data
         });
        return cardInfo
        
    },
)


export function potentialAutocompleteMatches(string) {
    return axios.get("https://api.scryfall.com/cards/autocomplete?q=" + string)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }


export function potentialCommanderAutocompleteMatches(string) {
  return axios.get("https://api.scryfall.com/cards/search?q=" + string + "++((is:commander+f:commander))")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}  
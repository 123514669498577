// Define initial state
const initialState = {
  isLoading: false,
};

// Reducer function
const loadingSlice = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_START":
      return {
        ...state,
        isLoading: true,
      };
    case "LOADING_FINISH":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

// Action creators
export const startLoading = () => ({
  type: "LOADING_START",
});

export const finishLoading = () => ({
  type: "LOADING_FINISH",
});

export default loadingSlice;

import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';


export function TasigurPodArticle()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px'}} className={styles.textbody}>
                        <br></br>
                        <h1>Tasigur Pod - Deck Tech Series</h1>
                        <h3>Published: Mar. 31st 2024 </h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Commander" color="orange" />
                        <br></br>
                        <br></br>
                        Hello Folks-
                        <br></br>
                        <br></br>
                        Today I’d like to do a deck tech of my Tasigur Pod - a Sultai birthing pod deck.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/8/1/81f93ac5-d149-4ccf-8b99-13ecf3190c29.jpg?1562827104"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        The glorious banana king. 
                        <br></br>
                        <br></br>
                        Here it is if you want to have a quick browse before I do a more thorough walkthrough: 
                        <br></br>
                        <a target="_blank" rel="noopener noreferrer" href="https://sorinslist.com/deck/AzoriusEmployeePermanent">Tasgiur Pod</a>
                        <br></br> 
                        <br></br>
                        One of the best reasons to play Tasigur, the Golden Fang, is to have access to a “cheap” 6 mana creature thanks to the delve keyword.
                        <br></br>
                        By playing ramp and using fetchlands as well as filling our graveyard using cheap self-mill cards, we can often get Tasigur out very early.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/1/0/10e65e74-14fc-4286-84d3-36c70d6dcf46.jpg?1675645189"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/1/5/157f343d-8583-4827-a77d-d916e6a5caa1.jpg?1571453958"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        Fill our graveyard for value. 
                        <br></br>
                        <br></br>
                        Once our mana base is developed and we have Tasigur out, he blocks well and “durdles” well with his activated ability until we can find a pod enabler.
                        <br></br>
                        These are Birthing Pod (the namesake), Prime Speaker Vannifar, Neoform, and Eldritch Evolution.
                        <br></br>
                        With any of these cards, we can cheat out a 7 or 8 drop in the midgame, and we can even pick the best one from our deck to answer the current board state. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/b/7/b768efa2-e56b-4a7e-ace8-d673f10e0714.jpg?1562880960"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/8/4/84abfc59-10a7-4cb5-9cdd-81797116c810.jpg?1572893948"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/2/92d8f67e-4f2f-4a1f-b190-7c3f39e477e4.jpg?1557577201"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                                                <Image
                            src={"https://cards.scryfall.io/large/front/e/f/efcb00e5-2caa-45c8-ad19-05d45c683d16.jpg?1576384769"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <br></br>
                        Our Pod Enablers 
                        <br></br>
                        <br></br>
                        Any of our 7 drops are fun to play with, and we can customize our deck to play whatever 7 drops we want.
                        <br></br>
                        - Toxrill, the Corrosive can clear creature tokens pretty effectively
                        <br></br>
                        - Sheoldred, Whispering One can effectively clear opponents creatures when they only have one big creature on the board
                        <br></br>
                        - Koma, Cosmos Serpent can tap down and prevent Planeswalkers from using abilities during upkeep
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/8/4/84e64f38-b1f3-47cd-8cfb-a4861369aca3.jpg?1643590379"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/b/3bb8347b-8663-40b8-bdfb-411236d2efc8.jpg?1562876780"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/8/7/876ad1df-e926-4d89-aad7-499a37b27f4c.jpg?1631055412"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        The Payoffs.
                        <br></br>
                        <br></br>
                        That’s the main idea of this deck, but note that since Birthing Pod and Prime Speaker Vannifar are permanents, we can actually pod multiple times on our own turn, and from various mana values. 
                        <br></br>
                        <br></br>
                        Therefore the interesting thing about building a pod deck comes from choosing the various “chain” of creatures that you can pod along with.
                        <br></br>
                        For example, if we don’t have access to Tasigur (or we’ve already pod’ed him), 
                        <br></br>
                        we could do a value pod by sacrificing Solemn Simulacrum (aka Sad Robot), a 4-drop, to get a Vorinclex, a 5-drop that ETB grabs two lands. 
                        <br></br>
                        Then we simultaneously benefit from a death trigger as well as an ETB trigger.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/c/7/c7e10ca7-1e5d-4224-82cf-798a4d436d72.jpg?1562879671"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/b/9b89b2e5-a34c-48a0-a054-6a459ebe60c9.jpg?1682694018"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/2/e24b5289-d3b5-4b4d-bb37-69bf2c3b48bc.jpg?1689246885"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        It’s also worth noting that we run several “untappers” for this purpose, Kiora planeswalker and Kiora’s Follower - they can either be used for ramp by untapping our lands, or to untap our “pod” permanents so we can go 3-> 4 -> 5 pod in a single turn.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/c/ec9ce307-8ec3-4db6-a4a3-49f92ebcc783.jpg?1593092775"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/c/4/c4cf8664-a29d-4d64-af14-7c2002488ba2.jpg?1585402058"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        Double up on our pod effects!
                        <br></br>
                        <br></br>
                        The rest of the deck is a lot of your typical commander Sultai interactions pieces:
                        <br></br>
                        ramp, tutors, card-draw, and utility. 
                        <br></br>
                        Since the pod combo is relatively fragile (if eldritch evolution gets countered, you are down 2-1’ed and lose the very nice blocker that a 4/5 is), it pays to hold off on podding until you can defend it with a counterspell.
                        <br></br>
                        <br></br>
                        Additionally, since the deck runs a higher than average number of 7+ drops, it helps to have a couple of reanimator effects, but that’s not the main goal of the deck, so I chose to run just a few.
                        <br></br>
                        <br></br>
                        In practice, some things to watch out for when playing this deck are 
                        <br></br>
                        <br></br>
                        - It’s important to learn the chain of mana values in the deck because a lot of your library gets milled as you are playing, so your desired pod target might be in the graveyard.
                        <br></br>
                        - After you successfully pod, the important thing is to rebuild your hand and graveyard so you can pod again when your big threats get removed.
                        <br></br>
                        - Playing your land drop every turn is pivotal when the average CMC is so skewed due to the higher drops. Don’t be afraid to tutor for a land.
                        <br></br>
                        - We have lots of interaction. Try to never tap out in the mid to late game.
                        <br></br>
                        <br></br>
                        This deck is very flexible and can be played in many different ways thanks to the searching power of the pod.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/8/4/847a175e-ead1-4596-baf3-5f7f57859e0b.jpg?1674421689"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/b/d/bd9af767-42da-46c7-a2b8-3957b2e3063f.jpg?1547516310"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        The main weakness is that it is a sorcery speed ability but hopefully the potency of the 7-drops can make up for it! 
                        <br></br>
                        <br></br>
                        Let us know if you try this deck or a variation. (We’re working on a  comments section!)
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};
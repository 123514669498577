import styles from "./Banner.module.css";
import { useSelector, useDispatch } from 'react-redux';
import { Input, Select, Space } from 'antd';
import background from "../assets/ChibiSorin.webp";
import { useEffect } from "react";
import { setDeckName, setFormat } from "../redux/deckMetaDataSlice";
export default function Banner(props) {

    // State Controllers
    let deckMetaData = useSelector((state) => state.deckMetaData);

    useEffect(() => {
        // Force refresh when deckMetaData is updated
    }, [deckMetaData])

    // State Dispatchers
    const dispatch = useDispatch();
    const handleFormatChange = ((new_format) => {
        console.log(new_format)
        console.log("handle format changing")
        dispatch(setFormat(new_format))
    })
    const handleDeckNameChange = ((event) => {
        let new_deck_name = document.getElementById("deck-name").value;
        dispatch(setDeckName(new_deck_name));
    })

    // Drop-down
    const { Option } = Select;
    const selectAfter = (
        <Select defaultValue={deckMetaData.format} onChange={handleFormatChange}>
            <Option value="Commander">Commander</Option>
            <Option value="Standard">Standard</Option>
            <Option value="Modern">Modern</Option>
            <Option value="Pioneer">Pioneer</Option>
            <Option value="Limited">Limited</Option>
            <Option value="None">None</Option>
        </Select>
    );

    // Render
    return ( 
        <div className={styles.bannerWrapper} >
             <div className={styles.bannerLeftBlock}>
                <Space className={styles.bannerInputsWrapper} direction="vertical">
                    <Input addonAfter={selectAfter}
                        defaultValue={deckMetaData.deckName}
                        id="deck-name"
                        onBlur={handleDeckNameChange}/>
                </Space>
            </div>
            <div className={styles.bannerRightBlock} style={{ backgroundImage:`url(${background})` }}>
            </div>
        </div>
    )
}

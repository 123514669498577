import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { sortAllColumnsByCMC, sortAllColumnsByColor, sortAllColumnsByRarity } from '../util/sort';


// Define initial state
const initialState = {
  mainboard: [[],[],[],[],[],[],[]],
  sideboard: [[],[],[],[],[],[],[]],
  draftboard: [],
  defaultSortOrder: "Mana Value",
  displayStyle: "Visual Stacks"
};

// Thunk functions
export const fetchDecklist = createAsyncThunk('decks/fetchDecklists', async () => {
  //const response = await client.get('/fakeApi/todos')
  //return response.todos
})

const sortByMV = (mainboard) => {
  return sortAllColumnsByCMC(mainboard)
}

const sortByColor = (mainboard) => {
  return sortAllColumnsByColor(mainboard)
}

const sortByRarity = (mainboard) => {
  return sortAllColumnsByRarity(mainboard)
}

const deckListSlice = createSlice({
  name: "decklist",
  initialState,
  reducers: {
    setMainboard(state, action) {
      return {
        ...state,
        mainboard: action.payload
      }
    },
    setSideboard(state, action) {
      return {
        ...state,
        sideboard: action.payload
      }
    },
    setDraftboard(state, action) {
      return {
        ...state,
        draftboard: action.payload
      }
    },
    setDefaultSortOrder(state, action) {
      return {
        ...state,
        defaultSortOrder: action.payload
      }
    },
    setDisplayStyle(state, action){
      return {
        ...state,
        displayStyle: action.payload
      }
    },
    sortMainboardByManaValue(state){
      // Perform your operation on the state here
      const mainboard = [...state.mainboard]; // Create a shallow copy of the mainboard array
      const updatedSortOrder = sortByMV(mainboard); // Sort the copied array

      const updatedState = {
        ...state,
        mainboard: updatedSortOrder,
      };
      return updatedState;
    },
    sortMainbordByColor(state) {
      // Perform your operation on the state here
      const mainboard = [...state.mainboard]; // Create a shallow copy of the mainboard array
      const updatedSortOrder = sortByColor(mainboard); // Sort the copied array

      const updatedState = {
        ...state,
        mainboard: updatedSortOrder,
      };
      return updatedState;
    },
    sortMainbordByRarity(state) {
      // Perform your operation on the state here
      const mainboard = [...state.mainboard]; // Create a shallow copy of the mainboard array
      const updatedSortOrder = sortByRarity(mainboard); // Sort the copied array

      const updatedState = {
        ...state,
        mainboard: updatedSortOrder,
      };
      return updatedState;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDecklist.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchDecklist.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(fetchDecklist.rejected, (state, action) => {
        state.status = 'idle'
      })
  }
})


export const {
  setMainboard,
  setSideboard,
  setDraftboard,
  setDefaultSortOrder,
  setDisplayStyle,
  sortMainboardByManaValue,
  sortMainbordByColor,
  sortMainbordByRarity,
} = deckListSlice.actions

export default deckListSlice.reducer

import { Routes, Route } from "react-router-dom";
import HomeScreen from "../views/HomeScreen"
import Blog from "./Blog";
import About from "./About";
import DeckLoader from "./DeckLoader";
import ErrorPage from "../views/ErrorPage";
import TopMenu from "../components/TopMenu";
import { useSelector, useDispatch } from 'react-redux'
import { DragDropContext } from "react-beautiful-dnd"
import { setMainboard, setSideboard } from "../redux/deckListSlice";
import { setCardBatch, setDragStack, setPrimaryCard } from "../redux/draggingCardsSlice";
import { setShowAlert, setAlertMsg, setAlertType} from "../redux/alertSlice"
import cloneDeep from 'lodash/cloneDeep';
import { 
  sortByCMC,
  getCardDraggableId,
  removeItemsByIndices,
  getObjectsByIndices,
  insertObjectsByIndex
} from "../util/sort"
import styles from "./Container.module.css";
import { Alert, Space } from 'antd';


export default function Container() {

  // For Alerts
  const alertConfig = useSelector((state) => state.alerts);
  const decks = useSelector((state) => state.decks);
  const dragging = useSelector((state) => state.dragging);

  const dispatch = useDispatch();

  const updateBoards = (props) => {
    // If the drop location is valid
    let destination = props.destination
    if (destination != null) {
      // Allow swapping between different boards
      const regex = /(\w+)-/;
      let sourceBoard = regex.exec(props.source.droppableId)[1];
      let destinationBoard = regex.exec(props.destination.droppableId)[1];
      let sameBoard = (sourceBoard === destinationBoard)

      if (dragging.dragStack.length > 0) {
        // If dragging more than 1 card
        if (!sameBoard) {
          // Only permit group drag/drop operations on the same boards
          // I refuse to code this for multiple boards
          return
        }
        dropCardStack(props)

      }
      else {
        // If only dragging 1 card
        if (sameBoard) {
          updateOneBoard(props)
        }
        else {
          updateTwoBoards(props)
        }
      }
    }
  }

  const updateOneBoard = (props) => {
      // Identify which board we are updating
      const regex = /(\w+)-/;
      let sourceBoard = regex.exec(props.source.droppableId)[1];

      // use cloneDeep since its a double nested array
      let duplicate = cloneDeep(decks[sourceBoard])

      // Expand the board if the card is dropped in the last column of the board
      let columnDroppedIn = parseInt(props.destination.droppableId.charAt(props.destination.droppableId.length - 1)) + 1;
      let lastColumnIndex = decks[sourceBoard].length
      let cardDroppedInLastColumn = (columnDroppedIn === lastColumnIndex)
      if (cardDroppedInLastColumn) {
        duplicate.push([])
      }

      // Get information about its orginal location
      let sourceDroppableId = props.source.droppableId
      let cardSourceColumn = sourceDroppableId.charAt(sourceDroppableId.length - 1);
      let cardSourceColumnIndex = props.source.index

      // Get informatin about its target location
      let destinationDroppableId = props.destination.droppableId
      let cardDestinationColumn = destinationDroppableId.charAt(destinationDroppableId.length - 1);
      let cardDestinationColumnIndex = props.destination.index

      // Remove the object from the array
      let removedCard = duplicate[cardSourceColumn].splice(cardSourceColumnIndex, 1)[0]

      // Add the removed object to its new location
      duplicate[cardDestinationColumn].splice(cardDestinationColumnIndex, 0, removedCard)

      // Dispatch the change to Redux
      dispatchToRedux(sourceBoard, duplicate)
  }

  const updateTwoBoards = (props) => {

      // Allow swapping between different boards
      const regex = /(\w+)-/;
      let sourceBoard = regex.exec(props.source.droppableId)[1];
      let destinationBoard = regex.exec(props.destination.droppableId)[1];

      // Copy both boards we intend to modify
      let sourceDeck = cloneDeep(decks[sourceBoard])
      let destinationDeck = cloneDeep(decks[destinationBoard])


      // Expand the board if the card is dropped in the last column of the destination
      let columnDroppedIn = parseInt(props.destination.droppableId.charAt(props.destination.droppableId.length - 1)) + 1;
      let lastColumnIndex = decks[destinationBoard].length
      let cardDroppedInLastColumn = (columnDroppedIn === lastColumnIndex)
      if (cardDroppedInLastColumn) {
        destinationDeck.push([])
      }
      
      // Get information about its orginal location
      let sourceDroppableId = props.source.droppableId
      let cardSourceColumn = sourceDroppableId.charAt(sourceDroppableId.length - 1);
      let cardSourceColumnIndex = props.source.index

      // Get informatin about its target location
      let destinationDroppableId = props.destination.droppableId
      let cardDestinationColumn = destinationDroppableId.charAt(destinationDroppableId.length - 1);
      let cardDestinationColumnIndex = props.destination.index

      // Remove the object from the array
      let removedCard = sourceDeck[cardSourceColumn].splice(cardSourceColumnIndex, 1)[0]
      // Add the removed object to its new location
      destinationDeck[cardDestinationColumn].splice(cardDestinationColumnIndex, 0, removedCard)

      // Update both boards in Redux
      dispatchToRedux(sourceBoard, sourceDeck)
      dispatchToRedux(destinationBoard, destinationDeck)
  }

  const dropCardStack = (props) => {
      // Identify which board we are updating
      const regex = /(\w+)-/;
      let sourceBoard = regex.exec(props.source.droppableId)[1];

      // use cloneDeep since its a double nested array
      let duplicate = cloneDeep(decks[sourceBoard])

      // Expand the board if the card is dropped in the last column of the board
      let columnDroppedIn = parseInt(props.destination.droppableId.charAt(props.destination.droppableId.length - 1)) + 1;
      let lastColumnIndex = decks[sourceBoard].length
      let cardDroppedInLastColumn = (columnDroppedIn === lastColumnIndex)
      if (cardDroppedInLastColumn) {
        duplicate.push([])
      }

      // Array manipulations to drop the card stack at the index of the primary card being dragged
      // Get an array based off of "mainboard-01" to 01 and remove all those cards
      let arrayOfIndices = getCardDraggableId(dragging.cardBatch)
      removeItemsByIndices(duplicate, arrayOfIndices)

      // Get an array of those cards from the original deck
      let arrayOfCardStack = getObjectsByIndices(decks[sourceBoard], arrayOfIndices)
      
      // Sort the array
      sortByCMC(arrayOfCardStack)

      // Drop those cards at the destination
      let destinationDroppableId = props.destination.droppableId
      let cardDestinationColumn = destinationDroppableId.charAt(destinationDroppableId.length - 1);
      let cardDestinationColumnIndex = props.destination.index
      let indexToInsertAt = "" + cardDestinationColumn + cardDestinationColumnIndex

      insertObjectsByIndex(duplicate, arrayOfCardStack, indexToInsertAt)

      // Update the redux state
      dispatchToRedux(sourceBoard, duplicate)
      console.log(decks[sourceBoard])
      console.log(duplicate)

  }

  
  const popAllSelectedCards = (props) => {
    if (dragging.cardBatch.length > 1) {
      // Get information about its orginal location
      let sourceDroppableId = props.source.droppableId
      let cardSourceColumn = sourceDroppableId.charAt(sourceDroppableId.length - 1);
      let cardSourceColumnIndex = "" + props.source.index

      // Make the other cards invisible but the primary card visible
      let dragStack = dragging.cardBatch.filter((cardId)=>{
        const cardColumn = cardId.slice(-2, -1);
        const cardIndex = cardId.slice(-1);
        const identifier = cardColumn + "" + cardIndex
        const draggingCardIdentifier = cardSourceColumn + "" + cardSourceColumnIndex
        if (identifier === draggingCardIdentifier) {
          return false
        }
        return true
      })
      dispatch(setDragStack(dragStack))
    }
  }

  const removeAllSelectedCards = () => {
    dispatch(setCardBatch([]))
    dispatch(setDragStack([]))
    dispatch(setPrimaryCard([]))
  }

  const dispatchToRedux = (board, deck) => {
    switch (board) {
      case 'mainboard':
        dispatch(setMainboard(deck))
        break;
      case 'sideboard':
        dispatch(setSideboard(deck))
        break;
      default:
        dispatch(setMainboard(deck))
    }
  }


  const onClose = (e) => {
    console.log(e, 'Alert was closed.');
    dispatch(setShowAlert(false));
  };

  return (
    <div>
      <div className={styles.header}> 
        <TopMenu></TopMenu>
        {alertConfig.showAlert ? 
            <Alert
              message={alertConfig.alertMsg}
              type={alertConfig.alertType}  // success, info, warning, error
              closable
              onClose={onClose}
              style={{"zIndex": "100"}} // on top of everything
              banner
            />
        : 
          <div></div>
        }
      </div>

      <div
        className={styles.content}
      >

        <div className={styles.editor}>
          <DragDropContext
            onDragStart={(props)=>{
              popAllSelectedCards(props)
              dispatch(setPrimaryCard(props.draggableId))
            }}
            onDragUpdate={()=>{}}
            onDragEnd={(props)=>{
              updateBoards(props)
              removeAllSelectedCards(props)
            }}
            
          >
            <Routes>
                  <Route path="/"                element={<HomeScreen />}/>
                  <Route path="/load"            element={<DeckLoader />}/>
                  <Route path="/deck/:shortname" element={<HomeScreen />}/>
                  <Route path="/blog/:article?"   element={<Blog />}/>
                  <Route path="/about"           element={<About />}/>
                  <Route path="*"                element={<ErrorPage />}/>
            </Routes>
          </DragDropContext>
        </div>
      </div>

      <div
        className={styles.footer}
      >
      </div>
    </div>
  );
}
